.become_tutor_card1 {
  background: transparent !important;
}

.become_tutor_card1 .login-form {
  border: 1px solid #3d63781c;
  background-color: #eaeeff6e !important;
  border-radius: 20px;
}

.container_padding {
  padding-top: 80px;
}

.container_padding h1 {
  color: #6f7c8b;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 0.7rem !important;
}

.btn-back {
  background: linear-gradient(45deg, #902727, transparent);
}

.btn-skip {
  background: linear-gradient(45deg, #665f40, transparent) !important;
}

.btn-next {
  background-color: linear-gradient(45deg, #060606, transparent);
}

.bt_title {
  padding-top: 0px;
  z-index: 9;
  position: relative;
}

.bt_title .self-end {
  place-self: center;
}

.bt_help {
  padding-bottom: 12px;
}

.bt_help .media-body {
  padding-left: 26px;
}

.bt_help .icon {
  color: blueviolet;
  cursor: pointer;
}

.btn_finish {
  background: radial-gradient(#5c4e6e, transparent);
}

.email_confirm_title {
  background: #f3f6ff;
  padding: 5px;
  margin: 0px;
  border-radius: 10px;
  color: #414e5e;
  display: flex;
  align-items: center;
}

.email_confirm_title h5 {
  margin: 0;
  border-bottom: 1px solid #b9e2ff;
}

.step-title {
  margin-bottom: 10px !important;
}

.titleResponsive {
  text-align: center !important;
}

.btnMblView {
  display: flex !important;
}

.helpBtn {
  border: none;
  background-color: transparent;
  color: #f3803b;
}

.helpBtnFont {
  width: 30px;
  height: 30px;
}

@media only screen and (max-width: 1400px) {
  .phoneView {
    position: absolute !important;
    top: 12px !important;
    left: 8px !important;
    width: 83px !important;
  }
}

@media only screen and (max-width: 1000px) {
  .titleResponsive {
    margin-bottom: 18px !important;
    text-align: start !important;
  }

  .mblResponsive {
    display: flex !important;
    justify-content: space-between !important;
  }

  .responsive {
    /* text-align: start !important; */
    display: none !important;
  }

  .scheduleResponsive {
    margin-bottom: 20px !important;
  }
}

@media only screen and (max-width: 668px) {
  .scheduleResponsive {
    margin-bottom: 42px !important;
  }
}

@media only screen and (max-width: 440px) {
  .btnMblView {
    display: flex !important;
    flex-direction: column-reverse !important;
  }

  .btnResponsive {
    margin-bottom: 5px !important;
  }
}

@media only screen and (max-width: 375px) {
  .btnFinishMblView {
    margin-top: 5px !important;
  }
}

@media (max-width: 576px) {
  .become_tutor_card1 .login-form {
    border-radius: 0;
  }
}
