.delete-button {
  margin-bottom: 4px;
}

.mapboxgl-map {
  margin-bottom: 10px;
}

.mapboxgl-ctrl-geocoder {
  border: 1.5px solid black;
  width: 400px;
}

@media only screen and (max-width: 500px) {
  .mapboxgl-ctrl-geocoder {
    width: 200px !important;
  }
}

.align-right {
  text-align: right;
}

.asterisk::after {
  content: "*";
  color: red;
}

.inputFieldStyle {
  padding: 8.5px !important;
}

@media only screen and (max-width: 768px) {
  .topSpace {
    margin-top: 26px !important;
  }

  .subjectHelp::after {
    font-size: 12px;
    padding: 1px 2px;
  }
}

@media only screen and (max-width: 370px) {
  .topSpace {
    margin-top: 42x !important;
  }

  .subjectHelp::after {
    font-size: 10px;
  }
}

.hideAccount::after {
  content: "Account is hide now";

  position: absolute !important;

  display: none !important;
}

.hideAccount:hover::after {
  display: block !important;
}

.visibleAccount::after {
  content: "Account is visible now";

  position: absolute !important;

  display: none !important;
}

.visibleAccount:hover::after {
  display: block !important;
}

.subjectHelp::after {
  content: "If you select Subject at first, Subject Category and Sub-Category will be selected automatically.";

  position: absolute !important;
  left: 95px !important;
  bottom: 42px;

  display: none !important;
  background-color: rgb(245, 245, 245);
  border: 1px solid gray;
  border-radius: 0.5rem;
  padding: 2px 10px;
  z-index: 1;
}

.subjectHelp:hover::after {
  display: block !important;
  margin-bottom: 0 !important;
}

/* padding of navbar profile menu  */
.p-10 {
  padding: 10px;
}