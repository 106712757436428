.dashboardHeading {
  position: relative;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  /* left: 5%; */
  padding-left: 20px !important;
}
.dashboard-bg {
  height: 30px;
  margin-bottom: 30px;
  border-radius: 15px;
}

@media (max-width: 576px) {
  .dash_img {
    /* height: 140px; */
  }
}
