@media only screen and (max-width: 768px) {
  .profilePicMblView {
    width: 110px !important;
    height: 110px !important;
  }
}

/* Qualification , Experience, Pricing, schedule Mobile View  */
.MobileViewResponsive,
.scheduleMblView {
  margin-top: 30px !important;
}
.tableMobileView {
  margin-top: 30px !important;
}

/* ShareProfileModal.css */

.icon {
  border-radius: 50%;
  margin-right: 10px;
  font-size: 40px;
}

/* CSS for tooltips */
.icon-container {
  position: relative;
}

.icon-container:hover::after {
  content: attr(data-tip);
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 1;
  pointer-events: none;
}
/* Add this to your existing CSS or create a new CSS file */

.icon-row {
  display: flex; /* Display icons in a row */
  justify-content: space-between; /* Distribute icons evenly across the row */
  margin-bottom: 10px; /* Add spacing between rows, adjust as needed */
}

@media only screen and (max-width: 768px) {
  .cardMobileView {
    margin-bottom: 6px !important;
  }
  .card-absolute {
    margin-top: 5px !important;
  }
  .title {
    padding: 5px 15px !important;
  }

  .MobileViewResponsive {
    margin-top: 0px !important;
  }
  .degreeCourseMobileView,
  .positionMblView,
  .mblViewRes {
    font-size: 12px !important;
    margin-bottom: 0;
    line-height: 1.5 !important;
  }
  .insMobileView,
  .resultMobileView,
  .yearMobileView,
  .orgMobileView,
  .dateMblView {
    font-size: 11px !important;
    line-height: 1.5 !important;
  }

  /* pricing & schedule */
  .tableMobileView,
  .scheduleMblView {
    margin-top: 0 !important;
  }
}
