.newsletter {
  background: #c5c5c52e;

  padding: 60px;
  border-radius: 15px;
}

.newsletter h2 {
  color: #000000;
}
.subscribe input {
  border: none;
  height: 40px;
  margin-left: 20px;
  outline: none;
}

.subscribe {
  padding: 7px 10px;
  background: #fff;
  width: 45%;
  margin: auto;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 768px) {
  .subscribe {
    width: 100%;
  }
  .newsletter h2 {
    font-size: 20px;
  }
  .btn_subscribe {
    text-align: center !important;
    margin-top: 15px !important;
  }
}
