.schedule_modal {
  max-width: 742px !important;
}

.weekly_schdule_box {
  padding: 2px;
  border: 1px solid #e7d4d4;
  border-radius: 5px;
  margin: 10px;
  background: #a7d1d51a;
}

.sch_border_bottom {
  border-bottom: 1px solid #e6edf3;
  padding-bottom: 10px;
}
.calender_btn button {
  margin-right: 10px;
}

.calender_title h5 {
  place-content: center;
}

.tutor_timeslots {
  border-top: 1px solid #b2e7ff;
  padding: 10px;
  border-bottom: 1px solid #b2e7ff;
  margin-bottom: 10px;
}

.tutor_timeslots .row {
  margin: 0;
  padding: 10px 0 0px 0px;
}

.default_class,
.default_class .rbc-event-content {
  background-color: var(--theme-default) !important;
}

.default_class,
.default_class .rbc-event-label {
  background-color: var(--theme-default) !important;
  color: #ffffff !important;
}
.bgUnpaid,
.bgUnpaid .rbc-event-content {
  background-color: #808080 !important;
  color: #ffffff !important;
}

.bg-paid,
.bg-paid .rbc-event-content {
  background-color: #7dc006 !important;
  color: #ffffff !important;
}

.bg-paid,
.bg-paid .rbc-event-label {
  background-color: #7dc006 !important;
  color: #ffffff !important;
}

.bg-unpaid,
.bg-unpaid .rbc-event-content {
  background-color: #ff8819 !important;
  color: #ffffff !important;
}

.bg-unpaid,
.bg-unpaid .rbc-event-label {
  background-color: #ff8819 !important;
  color: #ffffff !important;
}

.bg-rescheduled,
.bg-rescheduled .rbc-event-content {
  background-color: #1d97ff !important;
  color: #ffffff !important;
}

.bg-rescheduled,
.bg-rescheduled .rbc-event-label {
  background-color: #1d97ff !important;
  color: #ffffff !important;
}

.bg-cancelled,
.bg-cancelled .rbc-event-content {
  background-color: #e52727 !important;
  color: #ffffff !important;
}

.bg-cancelled,
.bg-cancelled .rbc-event-label {
  background-color: #e52727 !important;
  color: #ffffff !important;
}

.bg-completed,
.bg-completed .rbc-event-content {
  background-color: #6362e7 !important;
  color: #ffffff !important;
}

.bg-completed,
.bg-completed .rbc-event-label {
  background-color: #6362e7 !important;
  color: #ffffff !important;
}

.bg-free,
.bg-free .rbc-event-label {
  background-color: #1d97ff  !important;
  color: #ffffff !important;
}

.bg-free,
.bg-free .rbc-event-content {
  background-color: #1d97ff  !important;
  color: #ffffff !important;
}

.cancelled_class,
.cancelled_class .rbc-event-content {
  text-decoration-line: line-through;
  background-color: red !important;
  color: #ffffff !important;
}

.cancelled_class,
.cancelled_class .rbc-event-label {
  text-decoration-line: line-through;
  background-color: red !important;
  color: #ffffff !important;
}

.text_right {
  text-align-last: right;
}
.text_blue {
  color: #2f16ff !important;
}
.text_red {
  color: #b50000 !important;
}

.cal_btn {
  width: 135px;
  margin-right: 10px;
  margin: 5px;
}

.bg_valid {
  background-color: #969d008a !important;
}
.bg_invalid {
  background-color: #ff1b1e8a !important;
}
.cal_footer_center {
  text-align-last: center;
  padding-top: 10px;
  border-top: 1px solid #34d2e6;
}

.ext_tuition_time {
  margin-bottom: 10px !important;
  padding-top: 4px;
  padding-bottom: 6px;
  /* border-top: 1px solid #34d2e6; */
  border-bottom: 1px solid #34d2e6;
}
.ext_tuition {
  background: #efefef;
  padding: 10px;
  border-radius: 10px;
}

.rbc-addons-dnd .rbc-calendar {
  margin: 0px !important;
}
.rbc-calendar {
  margin: 0px !important;
}

.btn_color li {
  border-radius: 10px !important;
}
.btn_color span {
  position: relative;
  bottom: 5px;
  padding: 0 10px 0px 0px;
}

.classListView_btn {
  position: relative;
  bottom: 34px;
}

@media only screen and (max-width: 767px) {
  .cal_footer_center .row {
    text-align: center;
  }
  .classListView_btn {
    position: relative;
    bottom: 0;
  }
}

.row-align-center {
  align-items: center;
}

.swal2-container.swal2-center > .swal2-popup {
  grid-row: 1 !important;
  /* width: revert; */
}

@media only screen and (max-width: 500px) {
  .rbc-time-content {
    font-size: 10px !important;
  }
  .rbc-event {
    padding-left: 0 !important;
    /* min-height: 20px; */
    /* border: 1px solid #265985;
     display: flex;
    max-height: 100%;
    min-height: 20px;
    flex-flow: nowrap;
    align-items: flex-start;
    overflow: hidden;
    position: absolute;
    text-align: left; */
  }
}
