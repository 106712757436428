.tutorViewResponsive {
  display: flex !important;
}

.filterListResponsive {
  margin-top: 38px !important;
}
.mblTabResponsive {
  display: none !important;
}

@media only screen and (max-width: 1200px) {
  .tutorViewResponsive {
    flex-direction: column-reverse !important;
  }
  .filterListResponsive {
    width: 100% !important;
  }
  .tabResponsive {
    width: 100% !important;
  }
  .mblTabResponsive {
    display: block !important;
  }
  .hideMobileView {
    display: none !important;
  }
  .applyBtn {
    margin-right: 3px !important;
  }
}

.col-md-3 {
  flex: 0 0 auto !important;
}

.listViewLink a:link {
  color: blue !important;
  background-color: transparent !important;
  text-decoration: none !important;
}

.listViewLink a:visited {
  color: red !important;
  background-color: transparent !important;
  text-decoration: none !important;
}

.listViewLink a:hover {
  color: green !important;
  background-color: transparent !important;
  text-decoration: underline !important;
}

.listViewLink a:active {
  color: greenyellow !important;
  background-color: transparent !important;
  text-decoration: underline !important;
}

.icon_visited {
  background: #63709154 !important;
}

.single_tutor {
  background: #f7f8f8;
  border-radius: 10px;
}

.single_tutor_dark {
  background: #262932;
  border-radius: 10px;
}

.single_tutor img {
  border-radius: 25px;
  height: 40px;
}

.search_tutor .form-control {
  height: 35px;
}

.tutor_name .new_tab_icon {
  visibility: hidden;
}

.tutor_name:hover .new_tab_icon {
  visibility: visible;
}

.info-block:hover {
  background-color: #97a0d829;
}

.search_tutor {
  justify-content: center;
  border: 1px solid #872d840d;
  padding: 12px;
  border-radius: 10px;
  background-color: #ece5f35e;
}

.filter_card_t {
  padding: 10px;
  border-radius: 10px;
  background: #f7f8f8;
  margin-bottom: 10px;
}

.filter_card_t_dark {
  padding: 10px;
  border-radius: 10px;
  background: #262932;
  margin-bottom: 10px;
}

.filter_card {
  padding: 10px;
  border-radius: 10px;
  background: #f7f8f8;
  margin-bottom: 10px;
}
.filter_card_dark {
  padding: 10px;
  border-radius: 10px;
  background: #262932;
  margin-bottom: 10px;
}

.f_title {
  margin-bottom: 7px;
}

.f_title span {
  border-bottom: 1px solid #e5cfcf;
  padding-bottom: 5px;
}

@media only screen and (max-width: 576px) {
  .search_title {
    padding-top: 15px;
  }
}

.pagination {
  position: relative !important;
}

.paginationBttns {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  width: 80%;
  height: 40px;
  list-style: none;
}

.paginationBttns a {
  padding: 10px;
  margin-left: 8px;
  border-radius: 5px;
  border: 1px solid #9797fa;
  color: #6362e7;
  cursor: pointer;
}

.paginationBttns a:hover {
  color: #ffffff !important;
  background-color: #3131e4 !important;
}

.paginationActive a {
  color: #ffffff !important;
  background-color: #6362e7;
}

.paginationDisabled a {
  color: grey;
  background-color: grey;
}

@media only screen and (max-width: 991px) {
  .paginationBttns {
    width: 100% !important;
    justify-content: center !important;
  }
}
@media only screen and (max-width: 668px) {
  .paginationBttns {
    width: 100% !important;
    justify-content: center !important;
  }
  .paginationBttns a {
    padding: 6px 8px !important;
    margin-left: 4px;
    border-radius: 3px;
  }
}

@media only screen and (max-width: 375px) {
  .paginationBttns a {
    padding: 3px 5px !important;
    margin-left: 3px;
  }
}

.clickHere {
  cursor: pointer !important;
}

.usdAlign {
  text-align: end !important;
  margin-top: 2px !important;
}

.moreFilters {
  text-align: right !important;
  margin-bottom: 0 !important;
}

@media only screen and (max-width: 768px) {
  .search_btn_public {
    place-content: center !important;
  }
  .usdAlign {
    text-align: start !important;
  }
}
