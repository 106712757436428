@media only screen and (max-height: 450px) {
  .mobileViewRes {
    height: 52vh !important;
    margin-bottom: 0 !important;
  }
  .mobileViewResImg {
    height: 56px !important;
  }
  .mobileViewResTitle {
    font-size: 10px !important;
  }
  .mobileViewResSubTitle {
    font-size: 10px !important;
  }
  .mobileViewResBtn {
    width: 10px !important;
    padding: 0.1px 0.4px !important;
  }
}

@media only screen and (max-height: 400px) {
  .mobileViewRes {
    height: 40vh !important;
  }
}
@media only screen and (max-height: 350px) {
  .mobileViewRes {
    height: 35vh !important;
  }
  .mobileViewResImg {
    height: 44px !important;
  }
}
@media only screen and (max-height: 290px) {
  .mobileViewRes {
    height: 20vh !important;
    margin-bottom: 20px !important;
  }
}

.mobileViewRes {
  height: calc(100vh - 20px) !important;
}

.sidebar_image {
  position: absolute;
  bottom: 4px;
  width: 240px;
}

.sidebar-img-section .sidebar-img-content {
  background: initial;
  padding-bottom: 10px;
  padding-top: 5px;
}

.mobileViewResBtn {
  min-inline-size: -webkit-fill-available;
  text-decoration: overline;
}
