@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300&display=swap");
.header {
  width: 100%;
  height: 80px;
  line-height: 80px;
}
.bg_navbar {
  background-color: #dfedfb;
  padding: 10px 0px 10px 0px !important;
}
.bg_navbar a {
  font-size: 15px;
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
}
.tuteairBrand {
  font-size: 25px !important;
  font-family: "Titillium Web", sans-serif;
  font-weight: 900;
}
.be_tutor {
  font-family: unset;
  /* font-size: medium; */
  /* border-bottom: 1px solid #6e7a8a; */
  padding: 8px 20px 8px 20px;
  border-radius: 20px;
  color: #5e52ba;
  margin: 0 0px 0 8px;
  background: #ffffffab;
}
.be_tutor:hover {
  background: #ffffff;
  color: #f76913;
}

.nav_active span {
  background-color: #f6dcc9;
}

.nav__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  column-gap: 1.3rem;
}

.nav__item a {
  font-weight: 500;
  font-size: 1.1rem;
  color: #0a2b1e;
  text-decoration: none;
  transition: 0.3s;
}

.nav__item a:hover {
  color: #17bf9e;
}

.nav__right p {
  font-weight: 500;
  color: #0a2b1e;
  font-size: 1.1rem;
}

.nav__right p i {
  font-size: 1.3rem;
}

.mobile__menu span i {
  font-size: 1.4rem;
  color: #0a2b1e;
  cursor: pointer;
}

.mobile__menu {
  display: none;
}

@media only screen and (max-width: 991px) {
  .nav__menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0a2b1ec2;
    z-index: 99;
    display: none;
  }

  .nav__list {
    position: absolute;
    background: #fff;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 999;
    padding-top: 80px;
  }

  .mobile__menu {
    display: block;
  }
  .nav__right {
    display: none;
  }

  .active__menu {
    display: block;
  }
  .nav-link {
    margin-bottom: 10px !important;
  }
  .be_tutor {
    font-family: unset;
    border-radius: 6px;
    margin-top: 4px !important;
  }
  .tuteair_navbar {
    margin-top: 9px !important;
  }
}

.profile-media img {
  height: 31px;
}

@media screen and (max-width: 1660px) and (min-width: 1366px) {
  .special-discount .img-wrraper {
    width: 37% !important;
  }
}
