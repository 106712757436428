.searchPage {
  padding-top: 80px;
  /* min-height: 100vh; */
}

.search_heading p {
  font-size: larger;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.search_img_1 {
  width: 12% !important;
  margin-left: 61px;
  padding-right: 0px !important;
  display: block;
}

@media only screen and (max-width: 768px) {
  .search_heading p {
    font-size: 15px;
    padding: 10px;
  }

  .search_img_1 {
    display: none;
  }

  .quick_search {
    width: 70% !important;
  }
  .listView {
    display: flex !important;
    flex-direction: row !important;
  }
  .mblListView {
    text-align: flex-end !important;
    width: 100% !important;
  }
}
