.ta-tutor {
  color: #f16901;
}
.ta-student {
  color: #6e7a8a;
}
.bg-tutor {
  background-color: #f16901 !important;
}
.bg-student {
  background-color: #6e7a8a !important;
}

.css-1s2u09g-control,
.home_input {
  background-color: #fffcfa !important;
}
