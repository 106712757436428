.choose__img img {
  border-radius: 15px;
}

.choose__content {
  padding-right: 50px;
}

.choose__content h2 {
  font-weight: 600;
}

.choose__img {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 777;
}

.play__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  z-index: 7777;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.play__icon i {
  color: #17bf9e;
  font-size: 2rem;
  padding: 10px;
}

.border__1 {
  /* border: 1px solid #e2ecfaba; */
  /* border-radius: 10px; */
  padding-top: 50px;
  padding-bottom: 50px;
}

.choose__content p {
  font-size: 17px;
}
.discount-detail p {
  font-size: 15px;
}
.discount-detail {
  margin-top: 15px;
}

.discount-block {
  padding-top: 30px;
}

@media only screen and (max-width: 768px) {
  .choose__content {
    padding-right: 0px !important;
  }
  .choose__content h2 {
    text-align: center;
    font-size: 18px;
  }

  .choose__content p {
    font-size: 14px;
    padding: 0 10px 0 10px;
    text-align: -webkit-center !important;
  }
  .discount-detail p {
    font-size: 14px;
  }

  .border__1,
  #about {
    padding-top: 0px;
  }
  .discount-detail h3 {
    font-size: 16px !important;
  }
}
