.total-sale {
  height: 160px !important;
  margin-bottom: 15px !important;
}

.total-sale span {
  font-size: 15px !important;
}

.border__2 {
  /* border: 1px solid #e2ecfaba; */
  /* border-radius: 10px; */
  padding-top: 50px;
  padding-bottom: 50px;
}

@media only screen and (max-width: 768px) {
  .total-sale span {
    text-align: center;
    font-size: 13px !important;
  }
  .total-sale h5 {
    text-align: center;
    font-size: 16px;
  }
}
