.scheduleMobileView {
  display: flex !important;
  justify-content: space-between !important;
}

@media only screen and (max-width: 100px) {
  .scheduleMobileView {
    display: block !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: center !important;
  }
}

@media only screen and (max-width: 768px) {
  .scheduleMobileView {
    display: block !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: center !important;
  }
}

.review-icon {
  cursor: pointer;
}
