@media only screen and (max-width: 768px) {
  .faq-wrap h1 {
    font-size: 21px;
    text-align: center;
  }
  .faq-wrap h3 {
    font-size: 18px;
    text-align: center;
  }
  .faq-wrap h5 {
    font-size: 15px;
    text-align: center;
  }
  .faq-wrap h6 {
    font-size: 15px;
  }
}
.desktopView {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem;
}

@media only screen and (max-width: 370px) {
  .mobileResponsive {
    padding-left: 0.5rem !important;
  }
  .faq-accordion .card .btn-link svg {
    top: 15px !important;
  }
}
