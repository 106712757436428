.title {
  background-color: #6362e7 !important;
}
.title th {
  color: #ffffff !important;
}
.tableData {
  background-color: lightblue !important;
}
.subAmount {
  background-color: rgb(172, 166, 166) !important;
  color: white !important;
}
.totalAmount {
  font-size: 15px !important;
  font-weight: 600 !important;
}

.payByCard {
  width: 180px !important;
  height: 35px;
  margin-top: 2px;
  outline: none;
  border: none;
  background-color: #6362e7;
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  border-radius: 5px;
  margin-bottom: 5px;
}
.amoutList {
  margin-top: 40px !important;
  margin-right: 50px !important;
}
.box {
  border: 1px solid red !important;
  width: fit-content !important;
  margin: 0 auto !important;
  margin-top: 10px !important;
  margin-right: 0 !important;
}

.summery_table input {
  width: 120px;
  border: 1px solid #e4c5c5 !important;
}
.paymentBanner {
  width: 100% !important;
  height: 70vh !important;
}
.payment {
  display: inline-block;
}

.payAllCard {
  width: 100% !important;
}

.payForClasses {
  background-color: rgb(172, 166, 166) !important;
  color: white !important;
}
.checkedAfterEffect {
  background-color: rgb(99, 191, 221) !important;
}

@media only screen and (max-width: 900px) {
  .payment {
    display: block;
    justify-content: space-between !important;
    margin-bottom: 10px;
    margin-right: 2px !important;
  }
  .mobileView {
    display: none !important;
  }
  .mblView {
    display: none !important;
  }
  .colonPosition {
    left: 11px !important;
  }
  .inputBox {
    width: 50px !important;
  }
}

@media only screen and (max-width: 768px) {
  .paymentBanner {
    height: 50vh !important;
    margin-bottom: 8px !important;
  }

  .payment {
    display: block;
    justify-content: space-between !important;
    margin-bottom: 10px;
    margin-right: 2px !important;
  }
  .mobileView {
    display: none !important;
  }
  .inputBox {
    width: 50px !important;
  }
}

@media only screen and (max-width: 450px) {
  .colonPosition {
    left: 4px !important;
  }

  .mobileView {
    display: none !important;
  }
  .mblView {
    display: none !important;
  }
  .inputBox {
    width: 50px !important;
  }
  .titleItem {
    font-size: 15px !important;
  }
}
@media only screen and (max-width: 376px) {
  .payment {
    display: block;
    justify-content: space-between !important;
    margin-bottom: 10px;
  }

  .inputBox {
    width: 40px !important;
  }
  .titleItem {
    font-size: 12px !important;
  }
  .colonPosition {
    left: 8px !important;
  }
}
@media only screen and (max-width: 330px) {
  .inputBox {
    width: 35px !important;
  }

  .colonPosition {
    left: 10px !important;
  }
  .payAllCard {
    width: 100% !important;
  }
}
